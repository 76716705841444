/*
@File: Addax Template Styles

* This file contains the styling for the actual tepmlate, this
is the file you need to edit to change the look of the
tepmlate.

This files table contents are outlined below>>>>>

*******************************************
*******************************************

*** - Default CSS
*** - Prealoader CSS 
*** - Main Header CSS
*** - Main Banner CSS
*** - Video Banner CSS
*** - Creative Banner CSS
*** - Particles Banner CSS
*** - Welcome Area CSS
*** - About Area CSS
*** - Who We Are Area CSS
*** - Strategy Area CSS
*** - CTR Area CSS
*** - Skill Area CSS
*** - Fun Facts Area CSS
*** - Team Area CSS
*** - How We Work CSS
*** - Services Area CSS
*** - Why We Are Different CSS
*** - Work Area CSS
*** - Pricing Area CSS
*** - FAQ Area CSS
*** - Testimonials Area CSS
*** - Blog Area CSS
*** - Partner Area CSS
*** - Subscribe Area CSS
*** - Contact Area CSS
*** - Page Title CSS
*** - Blog Details Area CSS
*** - Footer Area CSS
*** - Go Top CSS
*/
/*
==============================
Default CSS
==============================
*/
@import url('https://fonts.googleapis.com/css?family=Poppins:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i');

:root {
  scroll-behavior: initial;

  /* Colors: */
  --theme-color-000000: #000000;
  --theme-color-20293a: #20293a;
  --theme-color-9a00ff: #9a00ff;
  --theme-color-1718ff: #1718ff;
  --theme-color-ffffff: #ffffff;
  --theme-color-8f8f8f: #8f8f8f;

  /* Font/text values */
  --theme-font-family-poppins: Poppins;
  --theme-font-style-normal: normal;
  --theme-font-weight-600: 600;
  --theme-font-weight-normal: normal;
  --theme-font-weight-medium: medium;
  --theme-font-size-16: 16px;
  --theme-font-size-18: 18px;
  --theme-font-size-24: 24px;
  --theme-font-size-36: 32px;
  --theme-font-size-42: 40px;
  --theme-font-size-56: 56px;
  --theme-font-size-88: 72px;
  --theme-font-size-112: 88px;
  --theme-character-spacing-0: 0px;
  --theme-line-spacing-25: 25px;
  --theme-line-spacing-27: 27px;
  --theme-line-spacing-35: 35px;
  --theme-line-spacing-55: 55px;
  --theme-line-spacing-90: 90px;
  --theme-line-spacing-112: 112px;
  --theme-line-spacing-40: 40px;
  --theme-line-spacing-48: 48px;
}

/* Character Styles */
.theme-character-style-1 {
  color: var(--theme-color-ffffff);
  font-family: var(--theme-font-family-poppins);
  font-size: var(--theme-font-size-36);
  font-weight: var(--theme-font-weight-600);
  font-style: var(--theme-font-style-normal);
  line-height: var(--theme-line-spacing-40);
  letter-spacing: var(--theme-character-spacing-0);
}

.theme-character-style-2 {
  color: var(--theme-color-ffffff);
  font-family: var(--theme-font-family-poppins);
  font-size: var(--theme-font-size-16);
  font-weight: var(--theme-font-weight-medium);
  font-style: var(--theme-font-style-normal);
  line-height: var(--theme-line-spacing-25);
  letter-spacing: var(--theme-character-spacing-0);
}

.theme-character-style-3 {
  color: var(--theme-color-20293a);
  font-family: var(--theme-font-family-poppins);
  font-size: var(--theme-font-size-18);
  font-weight: var(--theme-font-weight-normal);
  font-style: var(--theme-font-style-normal);
  line-height: var(--theme-line-spacing-27);
  letter-spacing: var(--theme-character-spacing-0);
}

.theme-character-style-4 {
  color: var(--theme-color-ffffff);
  font-family: var(--theme-font-family-poppins);
  font-size: var(--theme-font-size-24);
  font-weight: var(--theme-font-weight-medium);
  font-style: var(--theme-font-style-normal);
  line-height: var(--theme-line-spacing-35);
  letter-spacing: var(--theme-character-spacing-0);
}

.theme-character-style-5 {
  color: var(--theme-color-20293a);
  font-family: var(--theme-font-family-poppins);
  font-size: var(--theme-font-size-42);
  font-weight: var(--theme-font-weight-600);
  font-style: var(--theme-font-style-normal);
  line-height: var(--theme-line-spacing-48);
  letter-spacing: var(--theme-character-spacing-0);
}

.theme-character-style-6 {
  color: var(--theme-color-ffffff);
  font-family: var(--theme-font-family-poppins);
  font-size: var(--theme-font-size-112);
  font-weight: var(--theme-font-weight-600);
  font-style: var(--theme-font-style-normal);
  line-height: var(--theme-line-spacing-112);
  letter-spacing: var(--theme-character-spacing-0);
}

.theme-character-style-7 {
  color: var(--theme-color-20293a);
  font-family: var(--theme-font-family-poppins);
  font-size: var(--theme-font-size-88);
  font-weight: var(--theme-font-weight-600);
  font-style: var(--theme-font-style-normal);
  line-height: var(--theme-line-spacing-90);
  letter-spacing: var(--theme-character-spacing-0);
}

.theme-character-style-8 {
  color: var(--theme-color-8f8f8f);
  font-family: var(--theme-font-family-poppins);
  font-size: var(--theme-font-size-18);
  font-weight: var(--theme-font-weight-normal);
  font-style: var(--theme-font-style-normal);
  letter-spacing: var(--theme-character-spacing-0);
}

.bg-000000 .theme-character-style-7,
.bg-f6f6f6 .theme-character-style-7 {
  color: var(--theme-color-ffffff);
}

.bg-000000 .theme-character-style-3,
.bg-f6f6f6 .theme-character-style-3 {
  color: var(--theme-color-9a00ff);
}

.capabilities .theme-character-style-1 {
  min-height: calc(3 * var(--theme-line-spacing-40));
}

body {
  margin: 0;
  padding: 0;
  background: #111;
  color: #fff;
  font: var(--theme-font-style-normal) normal var(--theme-font-weight-normal)
    var(--theme-font-size-18) / var(--theme-line-spacing-27)
    var(--theme-font-family-poppins);
  font-family: 'Poppins', sans-serif;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0;
  font-weight: 400;
  line-height: 1;
}

ul {
  margin-bottom: 0;
  padding-left: 0;
}

ul li {
  list-style: none;
}

:focus {
  outline: 0 !important;
}

p {
  margin-bottom: 0;
  color: #666666;
  font-weight: 300;
  line-height: 1.8;
}

a {
  color: #222222;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  text-decoration: none;
}

a:hover,
a:focus {
  color: #ff2c57;
  text-decoration: none;
}

img {
  max-width: 100%;
}

.ptb-80 {
  padding-top: 80px;
  padding-bottom: 180px;
}

.d-table {
  width: 100%;
  height: 100%;
}

.d-table-cell {
  vertical-align: middle;
}

.bg-f6f6f6 {
  background: #f6f6f6;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  margin-bottom: 9px;
}

.relative {
  position: relative;
}

/*Btn Btn-Primary*/
.btn {
  padding: 9px 25px;
  border-radius: 30px;
  font-size: 16px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.btn-primary {
  color: #fff;
  background-color: #ff2c57;
  border-color: #ff2c57;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary.disabled:hover,
.btn-primary.disabled:focus {
  color: #ff2c57;
  background-color: #fff;
  border-color: #ff2c57;
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #ff2c57;
  border-color: #ff2c57;
}

.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-primary.focus,
.btn-primary:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-primary.disabled,
.btn-primary:disabled {
  color: #fff;
  opacity: 1;
  background-color: #ff2c57;
  border-color: #ff2c57;
}

/* Section Title */
.section-title {
  /* padding-left: 30px; */
  position: relative;
  margin-bottom: 60px;
}

/* .section-title::before, .section-title::after {
  content: '';
  position: absolute;
  width: 3px;
  height: 100%;
  left: 10px;
  top: 0;
  background: #ff2c57;
  -webkit-transition: .4s;
  transition: .4s;
} */

/* .section-title::after {
  left: 0;
  height: 50%;
}

.section-title:hover::before {
  height: 50%;
}

.section-title:hover::after {
  height: 100%;
  
  .section-title h2 {
    font-size: 35px;
    font-weight: 200;
    margin-bottom: 20px;
  }
  
  .section-title h2 span {
    color: #ff2c57;
    font-weight: 600;
  }
} */

.section-title p {
  max-width: 635px;
  margin-top: -7px;
}

.section-title h4 {
  color: var(--theme-color-1718ff);
  font-size: 18px;
  font-weight: 300;
  /* margin-bottom: 17px; */
  text-transform: capitalize;
}

/* Form Control */
.form-control {
  height: 45px;
  padding: 10px 20px;
  border: 1px solid #eeeeee;
  border-radius: 0;
  font-size: 15px;
  background-color: #ffffff;
}

.form-control:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  border-color: #dddddd;
  outline: 0;
}

textarea.form-control {
  height: auto !important;
  min-height: auto;
}

/* Owl Carousel Button */
.owl-theme .owl-nav {
  margin-top: 0;
  border-radius: 50%;
}

.owl-theme .owl-dots .owl-dot span {
  width: 15px !important;
  height: 15px !important;
  margin: 0 4px !important;
  border: 2px solid #dddddd !important;
  border-radius: 50% !important;
  background: transparent !important;
  -webkit-transition: 0.4s !important;
  transition: 0.4s !important;
}

.owl-theme .owl-dots .owl-dot.active span {
  width: 35px !important;
  height: 10px !important;
  border-radius: 5px !important;
  background: #869791 !important;
  background: #ff2c57 !important;
  border-color: #ff2c57 !important;
}

.owl-theme .owl-dots .owl-dot:hover span {
  background: #ff2c57 !important;
  border-color: #ff2c57 !important;
}

.owl-theme .owl-nav [class*='owl-'] {
  position: absolute;
  top: 50%;
  left: 40px;
  width: 60px;
  height: 60px;
  margin-top: -20px;
  border-radius: 50% !important;
  background: rgba(255, 255, 255, 0.33) !important;
  color: #ffffff !important;
  font-size: 30px;
  line-height: 54px;
  text-align: center;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.owl-theme .owl-nav [class*='owl-']:hover {
  background: #ff2c57 !important;
  color: #ffffff !important;
}

.owl-theme .owl-nav .owl-next {
  right: 40px;
  left: auto;
}

/*
==============================
Preloader CSS
==============================
*/
.preloader {
  position: fixed;
  z-index: 99999;
  width: 100%;
  height: 100%;
  background: #ffffff;
}

.preloader .folding-cube {
  position: absolute;
  top: 50%;
  right: 0;
  left: 0;
  width: 60px;
  height: 60px;
  margin: -60px auto 0;
  -webkit-transform: rotateZ(45deg);
  transform: rotateZ(45deg);
}

.preloader .folding-cube .cube {
  position: relative;
  float: left;
  width: 50%;
  height: 50%;
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.preloader .folding-cube .cube::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-animation: sk-foldCubeAngle 2.4s infinite linear both;
  animation: sk-foldCubeAngle 2.4s infinite linear both;
  background-color: #ff2c57;
  -webkit-transform-origin: 100% 100%;
  transform-origin: 100% 100%;
}

.preloader .folding-cube .cube2 {
  -webkit-transform: scale(1.1) rotateZ(90deg);
  transform: scale(1.1) rotateZ(90deg);
}

.preloader .folding-cube .cube2::before {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}

.preloader .folding-cube .cube3 {
  -webkit-transform: scale(1.1) rotateZ(180deg);
  transform: scale(1.1) rotateZ(180deg);
}

.preloader .folding-cube .cube3::before {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}

.preloader .folding-cube .cube4 {
  -webkit-transform: scale(1.1) rotateZ(270deg);
  transform: scale(1.1) rotateZ(270deg);
}

.preloader .folding-cube .cube4::before {
  -webkit-animation-delay: 0.9s;
  animation-delay: 0.9s;
}

@-webkit-keyframes sk-foldCubeAngle {
  0%,
  10% {
    opacity: 0;
    -webkit-transform: perspective(140px) rotateX(-180deg);
    transform: perspective(140px) rotateX(-180deg);
  }

  25%,
  75% {
    opacity: 1;
    -webkit-transform: perspective(140px) rotateX(0deg);
    transform: perspective(140px) rotateX(0deg);
  }

  90%,
  100% {
    opacity: 0;
    -webkit-transform: perspective(140px) rotateY(180deg);
    transform: perspective(140px) rotateY(180deg);
  }
}

@keyframes sk-foldCubeAngle {
  0%,
  10% {
    opacity: 0;
    -webkit-transform: perspective(140px) rotateX(-180deg);
    transform: perspective(140px) rotateX(-180deg);
  }

  25%,
  75% {
    opacity: 1;
    -webkit-transform: perspective(140px) rotateX(0deg);
    transform: perspective(140px) rotateX(0deg);
  }

  90%,
  100% {
    opacity: 0;
    -webkit-transform: perspective(140px) rotateY(180deg);
    transform: perspective(140px) rotateY(180deg);
  }
}

/*
==============================
Main Header CSS
==============================
*/
.navbar {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
  background: var(--theme-color-000000) !important;
}

.header-sticky.is-sticky {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 0;
  background: var(--theme-color-000000) !important;
  -webkit-animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
  animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
  -webkit-box-shadow: 0 8px 6px -6px rgba(0, 0, 0, 0.4);
  box-shadow: 0 8px 6px -6px rgba(0, 0, 0, 0.4);
}

.navbar-light .navbar-brand {
  color: #ffffff;
  font-weight: 600;
  text-transform: uppercase;
}

.nav-link {
  color: var(--theme-color-ffffff);
}

.navbar-brand img {
  width: 120px;
  height: auto;
}

.navbar-light .navbar-brand span {
  color: var(--theme-color-1718ff);
  font-size: 35px;
  font-weight: 700;
  text-transform: lowercase;
}

.navbar-light .navbar-brand:focus,
.navbar-light .navbar-brand:hover {
  color: #ffffff;
}

.navbar-light .navbar-nav .nav-item {
  padding-right: 15px;
  padding-left: 15px;
}

.navbar-light .navbar-nav .nav-item:last-child {
  padding-right: 0;
}

.navbar-light .navbar-nav .nav-item .nav-link {
  padding-right: 0;
  padding-left: 0;
  color: var(--theme-color-ffffff);
}

.navbar-light .navbar-nav .nav-item .nav-link.active,
.navbar-light .navbar-nav .nav-item .nav-link.show,
.navbar-light .navbar-nav .nav-item .nav-link:hover,
.navbar-light .navbar-nav .nav-item .nav-link:focus {
  color: var(--theme-color-1718ff);
}

.navbar-light .navbar-nav .active .nav-link,
.navbar-light .navbar-nav.show .nav-link {
  color: var(--theme-color-1718ff);
}

.navbar-light.header-sticky.is-sticky .navbar-brand {
  color: var(--theme-color-ffffff);
}

.navbar-light.header-sticky.is-sticky .navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
  color: var(--theme-color-ffffff);
}

.navbar-light.header-sticky.is-sticky .navbar-nav .nav-link.active,
.navbar-light.header-sticky.is-sticky .navbar-nav .nav-link.show,
.navbar-light.header-sticky.is-sticky .navbar-nav .nav-link:hover,
.navbar-light.header-sticky.is-sticky .navbar-nav .nav-link:focus {
  color: var(--theme-color-1718ff);
}

.navbar-light.header-sticky.is-sticky .navbar-nav .active .nav-link,
.navbar-light.header-sticky.is-sticky .navbar-nav .show .nav-link {
  color: var(--theme-color-1718ff);
}

/*
==============================
Main Banner CSS
==============================
*/
.main-banner {
  position: relative;
  height: 750px;
  overflow: hidden;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

/* .main-banner::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: .77;
} */

.item-bg-one {
  background-image: url('../images/Main_image.png');
}

.item-bg-2x {
  background-image: url('../images/MakeAppointment.jpg');
  background-position: 60% 20%;
}

.main-banner div canvas {
  position: absolute;
  top: 0;
  left: 0;
}

.main-banner-text {
  position: relative;
  text-align: left;
}

.main-banner-text h4 {
  color: #fff;
  font-size: 24px;
  font-weight: 200;
  letter-spacing: 1px;
}

.main-banner-text h1 {
  margin-top: 25px;
  margin-bottom: 30px;
  color: #fff;
  font-size: 55px;
  font-weight: 200;
  letter-spacing: 2px;
}

.main-banner-text h1 span {
  color: #ff2c57;
  font-weight: 600;
}

.main-banner-text h1 .Typewriter {
  display: inline-block;
  margin-left: 15px;
}

.main-banner-text p {
  max-width: 730px;
  margin: auto;
  margin-bottom: 30px;
  color: #fff;
}

.main-banner-text .btn:hover,
.main-banner-text .btn:focus {
  border-color: #ffffff;
}

.main-banner-text .view-work {
  margin-left: 10px;
  background: transparent;
  border-color: #fff;
}

.main-banner-text .view-work:hover,
.main-banner-text .view-work:focus {
  background: #ff2c57;
  color: #ffffff;
  border-color: #ff2c57;
}

.home-slides .owl-controls {
  margin-top: 0;
}

/*
==============================
Video Banner CSS
==============================
*/
.video-background {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -100;
  width: auto;
  min-width: 100%;
  height: auto;
  min-height: 100%;
}

/*
==============================
Creative Banner CSS
==============================
*/
.creative-banner-one {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  border-width: 10rem 100vw 0 0;
  border-color: transparent #ffffff #ffffff transparent;
  border-style: solid;
}

.creative-banner-two::before {
  content: '';
  position: absolute;
  right: 0;
  bottom: 0;
  width: 0;
  height: 0;
  border-top: 160px solid transparent;
  border-right: 50vw solid #ffffff;
}

.creative-banner-two::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 0;
  border-top: 160px solid transparent;
  border-left: 50vw solid #ffffff;
}

/*
==============================
Particles Banner CSS
==============================
*/
#particles-js-two {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/*
==============================
Welcome Area CSS
==============================
*/
.single-box {
  position: relative;
  padding: 30px 20px;
  text-align: center;
  overflow: hidden;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  -webkit-box-shadow: 0 0 30px 0 rgba(67, 67, 67, 0.15);
  box-shadow: 0 0 30px 0 rgba(67, 67, 67, 0.15);
}

.single-box:hover {
  -webkit-transform: translateX(5px);
  transform: translateX(5px);
  -webkit-box-shadow: unset;
  box-shadow: unset;
}

.single-box .icon {
  color: #ff2c57;
  font-size: 45px;
}

.single-box h3 {
  margin-top: 25px;
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: 600;
}

.single-box a {
  position: absolute;
  right: -20px;
  bottom: -20px;
  width: 45px;
  height: 40px;
  border-radius: 50% 0 0 0;
  line-height: 44px;
  text-align: center;
  opacity: 0;
  -webkit-box-shadow: 0px 0px 25px 0px rgba(0, 27, 103, 0.3);
  box-shadow: 0px 0px 25px 0px rgba(0, 27, 103, 0.3);
  visibility: hidden;
}

.single-box:hover a {
  right: 0;
  bottom: 0;
  background: #ff2c57;
  color: #ffffff;
  opacity: 1;
  visibility: visible;
}

/*
==============================
About Area CSS
==============================
*/
.about-area {
  background-position: center center;
}

.about-video {
  position: relative;
}

.about-video .video-btn {
  position: absolute;
  top: 50%;
  right: 0;
  left: 0;
  margin-top: -26px;
  text-align: center;
}

.about-video .video-btn a {
  position: relative;
  display: inline-block;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background: #ff2c57;
  color: #ffffff;
  font-size: 30px;
  line-height: 72px;
}

.about-video .video-btn a::before,
.about-video .video-btn a::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 0;
  display: block;
  width: 88px;
  height: 88px;
  border: 10px solid #fff;
  border-radius: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  -webkit-animation: pulse-border 1500ms ease-out infinite;
  animation: pulse-border 1500ms ease-out infinite;
  -webkit-transition: all 200ms;
  transition: all 200ms;
}

.about-video .video-btn a::after {
  width: 70px;
  height: 70px;
  -webkit-transition: all 200ms;
  transition: all 200ms;
}

@-webkit-keyframes pulse-border {
  0% {
    opacity: 1;
    -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0)
      scale(1.2);
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.2);
  }
}

@keyframes pulse-border {
  0% {
    opacity: 1;
    -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0)
      scale(1.2);
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.2);
  }
}

.about-addax {
  margin-top: 12px;
}

.about-addax .section-title {
  margin-bottom: 20px;
  padding-left: 0;
}

.about-addax .section-title::before,
.about-addax .section-title::after {
  display: none;
}

/* .about-addax .section-title h2 {
  font-size: 25px;
} */

.about-addax ul {
  margin-bottom: 30px;
}

.about-addax ul li {
  margin-bottom: 10px;
  color: #666666;
  font-weight: 300;
}

.about-addax ul li i {
  margin-right: 5px;
  color: #ff2c57;
}

.about-addax ul li:last-child {
  margin-bottom: 0;
}

.about-addax .pull-left {
  margin-right: 45px;
}

/*
==============================
Who We Are Area CSS
==============================
*/
/* .who-we-are {
  padding-bottom: 50px;
} */

.single-who-we-are {
  position: relative;

  min-height: 270px;
  margin-bottom: 30px;
  padding: 50px 30px;
  border-radius: 5px;
  background: #ffffff;
  overflow: hidden;
  -webkit-transition: all 0.5s ease-in-out 0s;
  transition: all 0.5s ease-in-out 0s;
  -webkit-box-shadow: 0 20px 40px 0 rgba(28, 30, 35, 0.2);
  box-shadow: 0 20px 40px 0 rgba(28, 30, 35, 0.2);
}

.single-who-we-are:hover {
  /* background: #ff2c57;
  color: #ffffff; */
  -webkit-transform: translateX(5px);
  transform: translateX(5px);
}

.single-who-we-are i {
  color: #ff2c57;
  font-size: 50px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

/* .single-who-we-are:hover i {
  color: #ffffff;
} */

.single-who-we-are h4 {
  margin-top: 25px;
  margin-bottom: 20px;
  font-size: 22px;
  font-weight: 500;
}

.single-who-we-are p {
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

/* .single-who-we-are:hover p {
  color: #ffffff;
} */

.single-who-we-are span {
  position: absolute;
  right: 0;
  bottom: 0;
  color: rgba(0, 0, 0, 0.03);
  font-size: 200px;
  line-height: 180px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.who-we-are img {
  width: 70px;
}

.service-item img {
  width: 194px;
  height: auto;
}

/* .single-who-we-are:hover span {
  color: rgba(255, 255, 255, 0.2);
} */

/*
==============================
Strategy Area CSS
==============================
*/
.strategy-area {
  position: relative;
  overflow-x: hidden !important;
}

.strategy-area .row div {
  position: relative;
}

.about-strategy {
  padding-left: 25px;
}

.about-strategy .section-title {
  margin-bottom: 20px;
  padding-left: 0;
}

.about-strategy .section-title::before,
.about-strategy .section-title::after {
  display: none;
}

.about-strategy ul {
  margin-bottom: 30px;
}

.about-strategy ul li {
  margin-bottom: 10px;
  color: #666666;
  font-weight: 300;
}

.about-strategy ul li:last-child {
  margin-bottom: 0;
}

.about-strategy ul li i {
  margin-right: 5px;
  color: #ff2c57;
}

/*
==============================
CTR Area CSS
==============================
*/
.ctr-area {
  position: relative;
  text-align: center;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.ctr-area::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000000;
  opacity: 0.88;
}

.ctr-area .section-title {
  padding-left: 0;
}

.ctr-area .section-title::after,
.ctr-area .section-title::before {
  display: none;
}

.ctr-area .section-title h2 {
  margin-top: 30px;
  margin-bottom: 30px;
  color: #ffffff;
  font-weight: 500;
}

.ctr-area .section-title p {
  margin-right: auto;
  margin-bottom: 25px;
  margin-left: auto;
  color: #ffffff;
}

.ctr-area .view-work {
  margin-left: 10px;
  background: transparent;
  border-color: #ffffff;
}

.ctr-area .view-work:hover,
.ctr-area .view-work:focus {
  background: #ff2c57;
  color: #ffffff;
  border-color: #ff2c57;
}

.ctr-area .btn:hover,
.ctr-area .btn:focus {
  border-color: #ffffff;
}

/*
==============================
Skill Area CSS
==============================
*/
.about-skill .section-title {
  padding-left: 0;
}

.about-skill .section-title::before,
.about-skill .section-title::after {
  display: none;
}

.about-skill .progress-title {
  margin-bottom: 13px;
  font-size: 17px;
}

.about-skill .progress {
  height: 10px;
  margin-bottom: 30px;
  border-radius: 5px;
  background: #fff;
  overflow: visible;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.about-skill .progress .progress-bar {
  position: relative;
  border-radius: 5px;
  background: #ff2c57;
  overflow: initial;
  -webkit-animation: animate-positive 2s;
  animation: animate-positive 2s;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.about-skill .progress .progress-value {
  position: absolute;
  top: -29px;
  right: 0;
  color: #222222;
  font-size: 15px;
  font-weight: 300;
}

@-webkit-keyframes animate-positive {
  0% {
    width: 0;
  }
}

@keyframes animate-positive {
  0% {
    width: 0;
  }
}

.skill-video {
  position: relative;
}

.skill-video .video-btn {
  position: absolute;
  top: 50%;
  right: 0;
  left: 0;
  margin-top: -26px;
  text-align: center;
}

.skill-video .video-btn a {
  position: relative;
  display: inline-block;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background: #ff2c57;
  color: #ffffff;
  font-size: 30px;
  line-height: 72px;
}

.skill-video .video-btn a::before,
.skill-video .video-btn a::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 0;
  display: block;
  width: 88px;
  height: 88px;
  border: 10px solid #ffffff;
  border-radius: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  -webkit-animation: pulse-border 1500ms ease-out infinite;
  animation: pulse-border 1500ms ease-out infinite;
  -webkit-transition: all 200ms;
  transition: all 200ms;
}

.skill-video .video-btn a::after {
  width: 70px;
  height: 70px;
  -webkit-transition: all 200ms;
  transition: all 200ms;
}

@-webkit-keyframes pulse-border {
  0% {
    opacity: 1;
    -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0)
      scale(1.3);
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.3);
  }
}

@keyframes pulse-border {
  0% {
    opacity: 1;
    -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0)
      scale(1.3);
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.3);
  }
}

/*
==============================
Fun Facts Area CSS
==============================
*/
.funFacts-area {
  position: relative;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.funFacts-area::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000000;
  opacity: 0.77;
}

.funFact {
  position: relative;
  padding-left: 65px;
}

.funFact i {
  position: absolute;
  top: 2px;
  left: 0;
  color: #ff2c57;
  font-size: 50px;
}

.funFact .fa.fa-users {
  left: -10px;
}

.funFact h2 {
  margin-bottom: 5px;
  color: #ffffff;
  font-size: 45px;
  font-weight: 600;
}

.funFact p {
  color: #ffffff;
  font-size: 18px;
}

/*
==============================
Team Area CSS
==============================
*/
.team-slider.owl-theme .owl-nav.disabled + .owl-dots {
  margin-top: 30px;
}

.our-team {
  position: relative;
  text-align: center;
}

.our-team .team-content {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 92%;
  margin-left: 15px;
  padding-top: 20px;
  background: #ffffff;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.our-team .team-content .title {
  margin-bottom: 6px;
  font-size: 20px;
  font-weight: 600;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.our-team .team-content .post {
  color: #666666;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.our-team:hover .team-content .title {
  color: #ffffff;
}

.our-team:hover .team-content .post {
  color: #ffffff;
}

.our-team:hover .team-content {
  bottom: 34%;
  background: transparent;
}

.our-team .social {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(20%, rgba(0, 0, 0, 0.9)),
    color-stop(80%, rgba(0, 0, 0, 0.3))
  );
  background: linear-gradient(
    to top,
    rgba(0, 0, 0, 0.9) 20%,
    rgba(0, 0, 0, 0.3) 80%
  );
  opacity: 0;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  visibility: hidden;
}

.our-team .social ul {
  position: absolute;
  top: 50%;
  right: 0;
  left: 0;
  margin-top: -50px;
  -webkit-transform: translateY(-35px);
  transform: translateY(-35px);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.our-team .social ul li {
  display: inline-block;
  margin: 0 4px;
}

.our-team .social ul li a {
  width: 35px;
  height: 35px;
  border: 1px solid #ffffff;
  border-radius: 50%;
  color: #ffffff;
  line-height: 32px;
}

.our-team .social ul li a:hover {
  background: #ff2c57;
  border-color: #ff2c57;
}

.our-team:hover .social {
  opacity: 1;
  visibility: visible;
}

.our-team:hover .social ul {
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

/*
==============================
How We Work CSS
==============================
*/
.how-work {
  background-position: center;
  background-size: contain;
  background-repeat: repeat;
}

.single-how-work {
  padding: 35px;
  border-radius: 5px;
  background: #ffffff;
  -webkit-transition: all 0.5s ease-in-out 0s;
  transition: all 0.5s ease-in-out 0s;
  -webkit-box-shadow: 0 0 30px 0 rgba(67, 67, 67, 0.15);
  box-shadow: 0 0 30px 0 rgba(67, 67, 67, 0.15);
}

.single-how-work:hover {
  -webkit-transform: translateX(5px);
  transform: translateX(5px);
}

.single-how-work h3 {
  color: #ff2c57;
  font-size: 45px;
  font-weight: 700;
}

.single-how-work h4 {
  margin-top: 25px;
  margin-bottom: 18px;
  font-size: 22px;
}

/*
==============================
Services Area CSS
==============================
*/
.services-area {
  padding-bottom: 50px;
}

.services-area .single-box {
  margin-bottom: 30px;
}

.single-services {
  position: relative;
  text-align: start;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  -webkit-box-shadow: 0 0 30px 0 rgba(67, 67, 67, 0.15);
  box-shadow: 0 0 30px 0 rgba(67, 67, 67, 0.15);
  height: 100%
}

.row.row-eq-height {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
}

/* .single-services:hover {
  -webkit-box-shadow: unset;
  box-shadow: unset;
  -webkit-transform: translateX(5px);
  transform: translateX(5px);
}

 .single-services::before {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 50px;
  border-left: 2px solid #ff2c57;
  height: 50px;
  border-bottom: 2px solid #ff2c57;
  opacity: 0;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.single-services:hover::before {
  opacity: 1;
  width: 100%;
  height: 50%;
} */

/* .single-services .services-img {
  position: relative;
}

.single-services .services-img::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #000000;
  opacity: 0.66;
}

.single-services .services-img .icon {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  margin-top: -30px;
} */

/* .single-services .services-img .icon:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
} */

.services-img {
  display: flex;
  align-items: center;
  align-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%;
  height: 216px;
}

.single-services i {
  width: 70px;
  height: 70px;
  border: 1px solid #ffffff;
  border-radius: 50%;
  color: #ffffff;
  font-size: 30px;
  line-height: 71px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.single-services:hover i {
  background: #ffffff;
  color: #ff2c57;
}

.single-services .services-content {
  position: relative;
  padding: 30px 25px;
}

.single-services .services-content h3 {
  margin-bottom: 18px;
  font-size: 22px;
  font-weight: 500;
}

.services-content,
.services-content p {
  color: var(--theme-color-000000);
}

/*
==============================
Why We Are Different CSS
==============================
*/
.tabset input[type='radio'] {
  display: none;
}

.tabset .tab-panel {
  display: none;
}

.tabset input:first-child:checked ~ .tab-panels .tab-panel:first-child {
  display: block;
}

.tabset input:nth-child(3):checked ~ .tab-panels .tab-panel:nth-child(2) {
  display: block;
}

.tabset input:nth-child(5):checked ~ .tab-panels .tab-panel:nth-child(3) {
  display: block;
}

.tabset input:nth-child(7):checked ~ .tab-panels .tab-panel:nth-child(4) {
  display: block;
}

.tabset input:nth-child(9):checked ~ .tab-panels .tab-panel:nth-child(5) {
  display: block;
}

.tabset input:nth-child(11):checked ~ .tab-panels .tab-panel:nth-child(6) {
  display: block;
}

.tabset label {
  position: relative;
  display: inline-block;
  margin-right: 6px;
  margin-bottom: 0;
  padding: 15px 32px;
  border: 1px solid #dddddd;
  border-radius: 5px;
  background: transparent;
  font-size: 18px;
  cursor: pointer;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.tabset label:hover,
.tabset label:focus,
.tabset label.checked {
  color: #ffffff;
  background-color: #ff2c57;
}

.tabset input:checked + label {
  background: #ff2c57;
  color: #ffffff;
  border-color: #ff2c57;
}

.tab-panel {
  margin-top: 35px;
}

.tab-panel .why-we-different-text h4 {
  margin-bottom: 20px;
  font-size: 35px;
  font-weight: 200;
}

.tab-panel .why-we-different-text p {
  max-width: 635px;
  margin-top: -7px;
}

.tab-panel .why-we-different-text ul {
  margin-top: 25px;
  margin-bottom: 30px;
}

.tab-panel .why-we-different-text ul li {
  margin-bottom: 10px;
  color: #666666;
  font-weight: 300;
}

.tab-panel .why-we-different-text ul li:last-child {
  margin-bottom: 0;
}

.tab-panel .why-we-different-text ul li i {
  margin-right: 5px;
  color: #ff2c57;
}

/*
==============================
Work Area CSS
==============================
*/
.shorting-menu {
  display: inline-block;
  background-color: var(--theme-color-ffffff);
}

.shorting-menu .filter {
  padding: 9px 24px;
  border: none;
  background: var(--theme-color-ffffff);
  color: var(--theme-color-1718ff);
  font-size: 16px;
  text-transform: capitalize;
  cursor: pointer;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  text-align: start;
}

.shorting-menu .filter.programs-filter-btn-active,
.shorting-menu .filter:hover {
  background: var(--theme-color-1718ff);
  color: #ffffff;
}

.shorting {
  margin-top: 40px;
}

.single-work {
  position: relative;
  padding: 5px;
  overflow: hidden;
}

.single-work img {
  width: 100%;
}

.single-work::before {
  content: '';
  position: absolute;
  top: 15px;
  left: 14px;
  width: 95%;
  height: 93%;
  background: #000000;
  opacity: 0;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  visibility: hidden;
}

.single-work:hover::before {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.66;
  visibility: visible;
}

.single-work .work-content {
  position: absolute;
  bottom: 20px;
  left: 26px;
  width: 86%;
  padding-top: 20px;
  padding-bottom: 20px;
  background: #ffffff;
  text-align: center;
  opacity: 0;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  visibility: hidden;
}

.single-work .work-content h4 {
  margin-bottom: 10px;
  font-size: 22px;
}

.single-work .work-content ul li {
  display: inline-block;
  font-size: 14px;
}

.single-work:hover .work-content {
  bottom: 35px;
  opacity: 1;
  visibility: visible;
}

.single-work .popup-btn {
  position: absolute;
  top: 20px;
  right: 40px;
  width: 45px;
  height: 45px;
  border: 1px solid #ffffff;
  border-radius: 50%;
  color: #ffffff;
  font-size: 22px;
  line-height: 46px;
  text-align: center;
  opacity: 0;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  visibility: hidden;
}

.single-work .popup-btn:hover {
  background: #ff2c57;
  border-color: #ff2c57;
}

.single-work:hover .popup-btn {
  top: 36px;
  opacity: 1;
  visibility: visible;
}

/*
==============================
Pricing Area CSS
==============================
*/
.pricingTable {
  position: relative;
  padding: 30px 0;
  text-align: center;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  -webkit-box-shadow: 0 0 30px 0 rgba(67, 67, 67, 0.15);
  box-shadow: 0 0 30px 0 rgba(67, 67, 67, 0.15);
  background-position: center center;
}

.pricingTable:hover {
  -webkit-transform: translateX(5px);
  transform: translateX(5px);
}

.pricingTable::after {
  content: '';
  position: absolute;
  top: 80px;
  right: 0;
  left: 0;
  width: 70px;
  height: 30px;
  margin: 0 auto;
  background: #ff2c57;
  -webkit-clip-path: polygon(50% 100%, 0 0, 100% 0);
  clip-path: polygon(50% 100%, 0 0, 100% 0);
}

.pricingTable .title {
  margin-bottom: 35px;
  padding: 20px 0;
  background: #ff2c57;
  color: #ffffff;
  font-size: 25px;
  text-transform: uppercase;
}

.pricingTable .price-value {
  margin-bottom: 25px;
  color: #ff2c57;
}

.pricingTable .price-value .amount {
  display: inline-block;
  font-size: 44px;
  font-weight: 500;
}

.pricingTable .price-value .month {
  display: block;
  margin-left: 50px;
  color: #666;
  font-size: 15px;
  line-height: 16px;
  text-transform: capitalize;
}

.pricingTable .currency {
  display: inline-block;
  margin-top: 6px;
  font-size: 25px;
  vertical-align: top;
}

.pricingTable .pricing-content {
  margin-bottom: 30px;
  padding-top: 25px;
  padding-bottom: 25px;
  border-top: 2px solid #eeeeee;
  border-bottom: 2px solid #eeeeee;
}

.pricingTable .pricing-content li {
  margin-top: 16px;
  color: #8b8b8b;
}

.pricingTable .pricing-content li:first-child {
  margin-top: 0;
}

.pricingTable .pricing-content li i {
  margin-right: 4px;
  color: #ff2c57;
}

/*
==============================
FAQ Area CSS
==============================
*/
.faq-area .image {
  position: absolute;
  top: 0;
  left: 15px;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.faq-area .row div {
  position: relative;
}

.accordion {
  border: none;
  border-radius: 0;
}

.accordion .accordion {
  border: none;
  border-radius: 0;
}

.accordion__item {
  margin-bottom: 10px;
  border-top: none;
  background: #ffffff;
  -webkit-box-shadow: 0 0 30px 0 rgba(67, 67, 67, 0.15);
  box-shadow: 0 0 30px 0 rgba(67, 67, 67, 0.15);
}

.accordion__item .accordion__button {
  color: #000000;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  background-color: #ffffff;
}

.accordion__item .accordion__button:hover,
.accordion__item .accordion__button:focus {
  color: #ffffff;
  background-color: #ff2c57;
}

.accordion__item .accordion__panel {
  padding: 20px;
  border-top: 1px solid #eeeeee;
  -webkit-animation: fadein 0.35s ease-in;
  animation: fadein 0.35s ease-in;
}

.accordion__item:last-child {
  margin-bottom: 0;
}

/*
==============================
Testimonials Area CSS
==============================
*/
.testimonials-area {
  padding-bottom: 50px;
}

.single-feedback {
  position: relative;
  margin-bottom: 30px;
  padding: 30px;
  border-radius: 5px;
  -webkit-transition: all 0.5s ease-in-out 0s;
  transition: all 0.5s ease-in-out 0s;
  -webkit-box-shadow: 0 0 30px 0 rgba(67, 67, 67, 0.15);
  box-shadow: 0 0 30px 0 rgba(67, 67, 67, 0.15);
}

.single-feedback .client-info {
  margin-bottom: 30px;
  overflow: hidden;
}

.single-feedback .client-info .client-pic {
  float: left;
  width: 30%;
}

.single-feedback .client-info .client-pic img {
  border-radius: 50%;
}

.single-feedback .client-info .client-title {
  float: left;
  width: 70%;
  margin-top: 17px;
  padding-left: 20px;
}

.single-feedback .client-info .client-title h4 {
  font-size: 19px;
  font-weight: 500;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.single-feedback .client-info .client-title h5 {
  margin-top: 13px;
  color: #666666;
  font-size: 15px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.single-feedback p {
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.single-feedback span {
  position: absolute;
  right: 0;
  bottom: 0;
  color: rgba(0, 0, 0, 0.03);
  font-size: 175px;
  line-height: 0;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.single-feedback:hover {
  background: #ff2c57;
  -webkit-transform: translateX(5px);
  transform: translateX(5px);
}

.single-feedback:hover .client-title h4,
.single-feedback:hover .client-title h5 {
  color: #ffffff;
}

.single-feedback:hover p {
  color: #ffffff;
}

.single-feedback:hover span {
  color: rgba(255, 255, 255, 0.2);
}

/*
==============================
Blog Area CSS
==============================
*/
.single-blog-item {
  position: relative;
  margin-bottom: 30px;
  padding: 30px;
  border: 1px solid #eeeeee;
  overflow: hidden;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.single-blog-item::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #ffffff;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.single-blog-item span,
.single-blog-item p,
.single-blog-item h4 {
  position: relative;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.single-blog-item h4 {
  margin-top: 15px;
  margin-bottom: 15px;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.5;
}

.single-blog-item h4 a:hover {
  color: #ff2c57 !important;
}

.single-blog-item .link-btn {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 45px;
  height: 40px;
  border-radius: 50% 0 0 0;
  line-height: 44px;
  text-align: center;
  -webkit-box-shadow: 0px 0px 25px 0px rgba(0, 27, 103, 0.3);
  box-shadow: 0px 0px 25px 0px rgba(0, 27, 103, 0.3);
}

.single-blog-item:hover::before {
  background: #000000;
  opacity: 0.66;
}

.single-blog-item:hover span,
.single-blog-item:hover p,
.single-blog-item:hover h4 a {
  color: #ffffff;
}

.single-blog-item:hover .link-btn {
  background: #ff2c57;
  color: #ffffff;
}

/*
==============================
Partner Area CSS
==============================
*/
.partner-slider.owl-theme .owl-nav.disabled + .owl-dots {
  margin-top: 30px;
}

.partner-area {
  text-align: center;
}

.partner-area .owl-carousel .owl-item img {
  width: 140px;
}

/*
==============================
Subscribe Area CSS
==============================
*/
.subscribe-area {
  text-align: center;
  background-position: center;
  background-size: contain;
  background-repeat: repeat;
  background-attachment: fixed;
}

.newsletter {
  max-width: 750px;
  margin-right: auto;
  margin-left: auto;
  padding: 35px;
  border-radius: 5px;
  background: #ffffff;
  -webkit-box-shadow: 0 0 30px 0 rgba(67, 67, 67, 0.15);
  box-shadow: 0 0 30px 0 rgba(67, 67, 67, 0.15);
}

.newsletter h4 {
  margin-bottom: 30px;
  font-size: 30px;
  text-transform: capitalize;
}

.newsletter form {
  position: relative;
}

.newsletter form .form-control {
  height: 75px;
  padding-left: 20px;
  border: none;
  border-radius: 50px;
  font-size: 17px;
  -webkit-box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
}

.newsletter form .btn {
  position: absolute;
  top: 8px;
  right: 7px;
  width: 175px;
  height: 60px;
}

.newsletter form .validation-danger {
  margin-top: 20px;
  color: red;
}

.newsletter form .validation-success {
  margin-top: 20px;
}

/*
==============================
Contact Area CSS
==============================
*/
#map {
  width: 100%;
  height: 400px !important;
}

.contact-info {
  padding: 30px;
  border-top: 3px solid #ff2c57;
  border-bottom: 3px solid #ff2c57;
  background: #ffffff;
  -webkit-box-shadow: 0 0 30px 0 rgba(67, 67, 67, 0.15);
  box-shadow: 0 0 30px 0 rgba(67, 67, 67, 0.15);
}

.contact-info ul li {
  position: relative;
  margin-bottom: 34px;
  padding-left: 60px;
  color: #151515;
  font-size: 16px;
}

.contact-info ul li:last-child {
  margin-bottom: 0;
}

.contact-info ul li i {
  position: absolute;
  top: -11px;
  left: 0;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background: #f6f6f6;
  color: #ff2c57;
  font-size: 19px;
  line-height: 45px;
  text-align: center;
}

.contact-info .follow-us {
  margin-top: 40px;
  text-align: right;
}

.contact-info .follow-us li {
  display: inline-block;
  margin-bottom: 0;
  padding-left: 0;
}

.contact-info .follow-us li:first-child {
  margin-right: 5px;
  font-size: 18px;
  font-weight: 400;
}

.contact-info .follow-us li a {
  width: 35px;
  height: 35px;
  border: 1px solid #000000;
  border-radius: 50%;
  line-height: 35px;
  text-align: center;
}

.contact-info .follow-us li a:hover,
.contact-info .follow-us li a:focus {
  background: #ff2c57;
  color: #ffffff;
  border-color: #ff2c57;
}

.contact-form {
  padding: 40px 30px;
  border-top: 3px solid #ff2c57;
  border-bottom: 3px solid #ff2c57;
  background: #ffffff;
  -webkit-box-shadow: 0 0 30px 0 rgba(67, 67, 67, 0.15);
  box-shadow: 0 0 30px 0 rgba(67, 67, 67, 0.15);
}

.contact-form h4 {
  margin-bottom: 25px;
  color: #ff2c57;
  font-size: 22px;
}

.contact-form .list-unstyled li {
  color: red;
}

.contact-form .form-control {
  border-radius: 30px;
}

.contact-form .btn {
  margin-top: 20px;
}

.contact-form #message {
  height: auto;
  padding: 15px;
}

/*
==============================
Page Title CSS
==============================
*/
.page-title {
  position: relative;
  height: 550px;
  text-align: center;
  overflow: hidden;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
}

.page-title::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000000;
  opacity: 0.6;
}

.page-title h1 {
  margin-top: 65px;
  margin-bottom: 35px;
  color: #ffffff;
  font-size: 45px;
  font-weight: 600;
}

.page-title ul li {
  display: inline-block;
  margin: 0 3px;
  color: #ffffff;
  font-weight: 500;
  text-transform: uppercase;
}

.page-title ul li.active {
  color: #cccccc;
}

.page-title ul li a {
  color: #ffffff;
}

.page-title ul li a:hover {
  color: #ff2c57;
}

/*
==============================
Blog Details Area CSS
==============================
*/
.blog-details-area {
  background-attachment: fixed;
}

.blog-details-area .title {
  margin-top: 30px;
  margin-bottom: 30px;
  font-size: 27px;
}

.blog-details {
  background: #ffffff;
  -webkit-box-shadow: 0 5px 10px rgba(0, 9, 128, 0.035),
    0 7px 18px rgba(0, 9, 128, 0.05);
  box-shadow: 0 5px 10px rgba(0, 9, 128, 0.035),
    0 7px 18px rgba(0, 9, 128, 0.05);
}

.blog-details .article-img {
  position: relative;
}

.blog-details .article-img .date {
  position: absolute;
  bottom: 15px;
  left: 15px;
  width: 100px;
  height: 100px;
  padding-top: 19px;
  border-radius: 5px;
  background: #ff2c57;
  color: #ffffff;
  font-size: 25px;
  font-style: italic;
  line-height: 32px;
  text-align: center;
}

.blog-details .article-text {
  padding: 30px;
}

.blog-details .article-text ul li {
  display: inline-block;
  margin-right: 4px;
}

.blog-details .article-text .category a {
  display: inline-block;
  padding: 3px 15px;
  border-radius: 3px;
  background: #ff2c57;
  color: #ffffff;
  font-weight: 400;
}

.blog-details .article-text p {
  margin-bottom: 20px;
}

.blog-details .article-text .blockquote {
  margin-top: 25px;
  margin-bottom: 25px;
  padding: 30px;
  border-left: 4px solid #ff2c57;
  background: #f4f4f4;
  font-size: 17px;
}

.blog-details .article-text .blockquote p {
  color: #000000;
}

.blog-details .article-text .author-share {
  margin-top: 40px;
}

.blog-details .article-text .author-share .social-share {
  margin-top: 28px;
  text-align: right;
}

.blog-details .article-text .author-share .social-share a {
  display: inline-block;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background: #ff2c57;
  color: #ffffff;
  line-height: 35px;
  text-align: center;
}

.blog-details .article-text .article-author {
  overflow: hidden;
}

.blog-details .article-text .article-author .author-img {
  float: left;
  width: 27%;
}

.blog-details .article-text .article-author .author-img img {
  width: auto !important;
  max-width: 100%;
  border-radius: 50%;
}

.blog-details .article-text .article-author .author-title {
  float: left;
  width: 73%;
  margin-top: 17px;
  padding-left: 25px;
}

.blog-details .article-text .article-author .author-title h4 {
  margin-bottom: 6px;
  font-size: 20px;
  text-transform: uppercase;
}

.blog-details .article-text .article-author .author-title span {
  color: #666666;
  font-size: 15px;
}

.leave-Link-reply {
  margin-top: 35px;
}

.post-controls-buttons {
  margin-top: 50px;
  margin-bottom: 50px;
  padding-right: 70px;
  padding-left: 70px;
}

.post-controls-buttons div {
  display: inline-block;
}

.post-controls-buttons div a {
  padding: 10px 20px;
  border-radius: 5px;
  background: #ffffff;
  color: #ff2c57;
  -webkit-box-shadow: 0 5px 10px rgba(0, 9, 128, 0.035),
    0 7px 18px rgba(0, 9, 128, 0.05);
  box-shadow: 0 5px 10px rgba(0, 9, 128, 0.035),
    0 7px 18px rgba(0, 9, 128, 0.05);
}

.post-controls-buttons div a:hover {
  background: #ff2c57;
  color: #ffffff;
}

.post-controls-buttons .controls-right {
  float: right;
}

.post-comments .single-comment {
  position: relative;
  margin-bottom: 25px;
  padding: 25px;
  background: #ffffff;
  overflow: hidden;
  -webkit-box-shadow: 0 5px 10px rgba(0, 9, 128, 0.035),
    0 7px 18px rgba(0, 9, 128, 0.05);
  box-shadow: 0 5px 10px rgba(0, 9, 128, 0.035),
    0 7px 18px rgba(0, 9, 128, 0.05);
}

.post-comments .single-comment.left-m {
  margin-left: 30px;
}

.post-comments .single-comment .comment-img {
  float: left;
  width: 13%;
}

.post-comments .single-comment .comment-img img {
  border-radius: 50%;
}

.post-comments .single-comment .comment-content {
  float: left;
  width: 87%;
  padding-left: 25px;
}

.post-comments .single-comment .comment-content h4 {
  margin-bottom: 15px;
  font-size: 20px;
  text-transform: uppercase;
}

.post-comments .single-comment .comment-content p {
  margin-bottom: 15px;
}

.post-comments .single-comment .comment-content a {
  position: absolute;
  right: 25px;
  bottom: 25px;
  color: #ff2c57;
  font-size: 17px;
  text-transform: uppercase;
}

.leave-a-reply {
  margin-top: 50px;
  padding: 25px;
  background: #ffffff;
  -webkit-box-shadow: 0 5px 10px rgba(0, 9, 128, 0.035),
    0 7px 18px rgba(0, 9, 128, 0.05);
  box-shadow: 0 5px 10px rgba(0, 9, 128, 0.035),
    0 7px 18px rgba(0, 9, 128, 0.05);
}

.leave-a-reply .btn {
  margin-top: 0px;
}

/*
==============================
Footer Area CSS
==============================
*/
.footer-area {
  padding: 50px 0;
  background: #1d1e21;
}

.footer-area h4 {
  margin-bottom: 30px;
  line-height: 22px;
}

.footer-area h4 a {
  color: #ffffff;
  font-size: 35px;
  font-weight: 600;
  text-transform: uppercase;
}

.footer-area h4 span {
  color: #ff2c57;
  font-size: 40px;
  font-weight: 700;
  text-transform: lowercase;
}

.footer-area ul li a {
  width: 35px;
  height: 35px;
  color: #ffffff;
  font-size: 15px;
  line-height: 34px;
}

footer a.fa-linkedin {
  width: 35px;
  height: 35px;
  margin-top: 15px;

  border: none;
  color: #fff;
  font-size: 20px;
  font-weight: 900;
  line-height: 34px;
  text-align: center;

  background-color: var(--theme-color-000000);
}

/* .footer-area ul li a:hover,
.footer-area ul li a:focus {
  background: #ff2c57;
  color: #ffffff;
  border-color: #ff2c57;
}

.footer-area p {
  line-height: 1;
  margin-top: 30px;
  color: #ffffff;
} */

/*
==============================
Go Top CSS
==============================
*/
.go-top {
  position: fixed;
  right: 20px;
  bottom: 30px;
  z-index: 1;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  color: #ffffff;
  font-size: 25px;
  line-height: 39px;
  text-align: center;
  cursor: pointer;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  background-color: #000000;
}

.go-top:hover {
  background: var(--theme-color-1718ff);
  color: #ffffff;
}

:invalid {
  -webkit-box-shadow: none;
  box-shadow: none;
}

:-moz-submit-invalid {
  box-shadow: none;
}

:-moz-ui-invalid {
  box-shadow: none;
}

.lfm__typing_effect {
  display: inline-block;
  padding-left: 12px;
}

.demo-modal-panel {
  position: fixed;
  top: 40%;
  right: 0;
  z-index: 99998;
  -webkit-transform: translateY(-40%);
  transform: translateY(-40%);
}

.demo-modal-panel .sidebar-demo-control {
  display: inline-block;
  padding: 0 0;
  padding: 12px 0 10px;
  border: none;
  border-radius: 5px 0 0 5px;
  color: #fff;
  font-size: 13px;
  font-weight: 600;
  text-transform: uppercase;
  cursor: pointer;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  -webkit-box-shadow: rgba(0, 0, 0, 0.15) 0 0 25px;
  box-shadow: rgba(0, 0, 0, 0.15) 0 0 25px;
  background-color: #ff2c57;
  letter-spacing: 1px;
}

.demo-modal-panel .sidebar-demo-control span {
  padding-right: 5px;
  padding-left: 5px;
  -webkit-writing-mode: vertical-rl;
  -ms-writing-mode: tb-rl;
  writing-mode: vertical-rl;
  text-orientation: upright;
}

.demo-modal-panel .sidebar-demo-control:hover {
  color: #fff;
  background-color: #000;
}

.example-demo-modal {
  position: fixed;
  top: 0;
  right: 0;
  right: -100%;
  z-index: 99999;
  border-left: 1px solid #eeeeee;
  opacity: 0;
  overflow: hidden;
  -webkit-transition: 0.9s;
  transition: 0.9s;
  visibility: hidden;
}

.example-demo-modal .inner {
  width: 450px;
  height: 100vh;
  padding: 90px 30px 30px 30px;
  -webkit-box-shadow: 0 0 30px 0 rgba(67, 67, 67, 0.15);
  box-shadow: 0 0 30px 0 rgba(67, 67, 67, 0.15);
  overflow-y: scroll;
  background-color: #fff;
}

.example-demo-modal .inner ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -10px;
  margin-bottom: 0;
  margin-bottom: -20px;
  margin-left: -10px;
  padding-left: 0;
  list-style-type: none;
}

.example-demo-modal .inner ul li {
  max-width: 50%;
  padding-right: 10px;
  padding-bottom: 20px;
  padding-left: 10px;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
}

.example-demo-modal .inner ul li .single-demo {
  position: relative;
  border-radius: 5px;
  text-align: center;
}

.example-demo-modal .inner ul li .single-demo img {
  border: 5px solid #fff;
  border-radius: 5px;
  -webkit-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}

.example-demo-modal .inner ul li .single-demo::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  opacity: 0;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  background-color: #ff2c57;
  visibility: hidden;
}

.example-demo-modal .inner ul li .single-demo span {
  position: absolute;
  top: 50%;
  right: 0;
  left: 0;
  z-index: 1;
  margin-top: 15px;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  text-transform: capitalize;
  opacity: 0;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: 0.5s;
  transition: 0.5s;
  visibility: hidden;
}

.example-demo-modal .inner ul li .single-demo .link-btn {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
  display: block;
  width: 100%;
  height: 100%;
}

.example-demo-modal .inner ul li .single-demo:hover::before {
  opacity: 1;
  visibility: visible;
}

.example-demo-modal .inner ul li .single-demo:hover span {
  margin-top: 0;
  opacity: 1;
  visibility: visible;
}

.example-demo-modal .header-title {
  position: absolute;
  top: -1px;
  right: 0;
  left: 0;
  z-index: 5;
  padding-top: 18px;
  padding-right: 30px;
  padding-bottom: 15px;
  padding-left: 30px;
  border-bottom: 1px solid #eeeeee;
  background-color: #fff;
}

.example-demo-modal .header-title .example-demo-modal-control {
  position: absolute;
  top: 15px;
  right: 25px;
  display: inline-block;
  padding: 0;
  border: none;
  color: #000;
  font-size: 20px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  background-color: transparent;
}

.example-demo-modal .header-title .example-demo-modal-control:hover {
  color: #ff2c57;
}

.example-demo-modal .header-title .title {
  margin-bottom: 0;
  color: #000;
  font-size: 18px;
  font-weight: 600;
}

.example-demo-modal.active {
  right: 0;
  opacity: 1;
  visibility: visible;
}

/* Dark version Style */
/* .section-title h2 {
  color: #fff;
} */

p {
  color: #fff;
}

.bg-f6f6f6 {
  background: #111;
}

.bg-000000 {
  background-color: var(--theme-color-000000);
}

.about-addax ul li {
  color: #fff;
}

.about-strategy ul li {
  color: #fff;
}

/* .single-who-we-are {
  background: #0f0f0f;
} */

.about-skill .progress .progress-value {
  color: #fff;
}

.our-team .team-content {
  background: #111;
}

.our-team .team-content .post {
  color: #fff;
}

.how-work {
  background: #171515;
  background-image: none;
}

.single-how-work {
  background: #0f0f0f;
}

.why-we-different {
  background: #171515;
}

.tab-panel .why-we-different-text ul li {
  color: #fff;
}

/* .shorting-menu {
  background: #0f0f0f;
}

.shorting-menu .filter {
  background: #1f1e1e;
  color: #fff;
} */

.pricingTable .pricing-content li {
  color: #fff;
}

.pricingTable .price-value .month {
  color: #fff;
}

.price-area {
  background: #171515;
}

.accordion__item .accordion__button {
  color: #ffff;
  background-color: #111;
}

.accordion__item + .accordion__item {
  border-top: none;
}

.accordion__item .accordion__panel {
  background-color: #111;
}

.testimonials-area {
  background: #171515;
}

.single-feedback .client-info .client-title h5 {
  color: #fff;
}

.single-blog-item {
  border: 1px solid #000;
}

.single-blog-item::before {
  background: #000;
}

.single-blog-item h4 a {
  color: #fff;
}

.partner-area {
  background: #171515;
}

.newsletter {
  background: #0f0f0f;
}

.contact-area {
  background: #171515;
}

.contact-form {
  background: #000;
}

.contact-info {
  background: #000;
}

.contact-info ul li {
  color: #fff;
}

.contact-info ul li a {
  color: #fff;
}

.single-blog-item .link-btn {
  color: #fff;
}

.creative-banner-one {
  border-color: transparent #111 #111 transparent;
}

.creative-banner-two::before {
  border-right: 50vw solid #111;
}

.creative-banner-two::after {
  border-left: 50vw solid #111;
}

.blog-details {
  background-color: #000;
}

.blog-details .article-text .blockquote {
  background: #222;
}

.blog-details .article-text .blockquote p {
  color: #fff;
}

.post-controls-buttons div a {
  background: #0f0f0f;
}

.post-comments .single-comment {
  background: #0f0f0f;
}

.leave-Link-reply {
  background: #0f0f0f;
}

.leave-Link-reply .form-control {
  border: 1px solid #222;
  background: #222;
  color: #fff;
}

/* End Dark version Style */

